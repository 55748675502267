import './App.css';

function App() {
  // return null;
  return (
    <div className="App">
      <header className="App-header">
        <h3>Average Quality</h3>
        <p>For business inquiries, please contact us by phone or email!</p>
        <p className="contacts">Email: <a className="App-link" href="mailto:contactus@averagequality.com">contactus@averagequality.com</a></p>
        <p className="contacts">Phone: <a className="App-link" href="tel:+17712221110">+1 771.222.1110</a></p>
      </header>
    </div>
  );
}

export default App;
